import { v4 as uuidv4 } from 'uuid'

const debug: string = ''

export const url = (debug == 'dev')   ? 'https://integrativadev.outerlamce.com/api/?'   :
			       (debug == 'teste') ? 'https://integrativateste.outerlamce.com/api/?' :
								 		'https://integrativa.outerlamce.com/api/?'


export async function postWebData(urlRequest: string, data: any, timeout: number = 5000) {
	const controller = new AbortController()
	const id = setTimeout(() => controller.abort(), timeout)

	const options = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data),
		signal: controller.signal
	}

	try {
		const response = await fetch(url, options)
		clearTimeout(id)
		
		if (!response.ok) 
			return null
		
		return await response.json()
	}
	catch (error) {
		//alert(error)
		return null
	}
}

export async function fetchApiData(request: string, timeout: number = 5000) {
	const controller = new AbortController()
	const id = setTimeout(() => controller.abort(), timeout)

	try {
		const response = await fetch(url + request, { signal: controller.signal })
		clearTimeout(id)

		if (!response.ok) 
			return { status: 'error', data: 'Response not OK' }

		return await response.json()
	}
	catch (error) {
		console.log('Erro ao buscar dados:', error)
		return { status: 'error', data: String(error) }
	}
}

export async function postApiData(data: any, request: any) {
	data['key'] = request
	
	const response = await postWebData(url, data)
	return response ? response : { status: 'error', data: 'erro HTTP' }
}

export async function getDatabaseRows(tableName: string, maxRows: null | number = null) {
	let data: any = {
		tableName: tableName
	}

	if (maxRows)
		data.limit = maxRows

	const response = await postApiData(data, 'getRows')

	if (!response)
		return []

	if (response.status != 'success')
		return []

	if (response.data.length == 0)
		return []

	return response.data
}

export async function getDataBaseCondition(tableName: string, columnName: string, desiredValue: string) {
	const data = {
		tableName: tableName,
		columnName: columnName,
		value: desiredValue,
	}

	const response = await postApiData(data, 'getData')

	if (!response || response.status != 'success')
		return []

	return response.data
}


export async function editDatabaseByCondition(tableName: string, columnName: string, desiredValue: string, newData: any) {
	const data = {
		tableName: tableName,
		columnName: columnName,
		desiredValue: desiredValue,
		newData: newData
	}

	return await postApiData(data, 'editData')
}

export async function addDataBaseRow(tableName: string, newData: any) {
	const data = {
		tableName: tableName,
		newData: newData
	}

	return await postApiData(data, 'addData')
}

export async function editDatabaseRowsByColumn(tableName: string, columnName: string, value: string, newValue: string) {
	const data = {
		tableName: tableName,
		columnName: columnName,
		value: value,
		newValue: newValue
	}

	return await postApiData(data, 'editRows')
}

export function genUUID(size: number = 30) {
	const uuid = uuidv4()
	return uuid.substring(0, size)
}

export async function sendFileDatabase(file: any, path: string) {
	const formData = new FormData();

	formData.append('key', 'sendDocs');
	formData.append('path', path);
	formData.append('file', file);
	try {
		const response = await fetch(url, {
			method: 'POST',
			body: formData
		})

		if (!response)
			console.error("Falha ao enviar documentos")
	}
	catch (error) {
		const e = error as Error
		console.error(e.message)
	}
}

export async function sendImageDatabase(file: any, id: string, tableName: string, i: number, type?: string) {
	if (!file.name)
		return null

	const formData = new FormData();
	const ext = file.name.split('.')[file.name.split('.').length - 1]
	const typeSuffix = type ? `_${type}` : '';
    const imgName = `${tableName}_${id}_${i}${typeSuffix}.${ext}`;
	formData.append(imgName, file, imgName);

	const options = {
		method: 'POST',
		body: formData
	}

	try {
		const response = await fetch(url + `pushImgs`, options);

		if (response.ok)
			return await response.text()

		return null
	}
	catch (error) {
		alert(error)
		return null
	}
}

export async function getImagePaths(imagePath: string) {
	const newURL = url + `getAllAvailableFiles`
	const options = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: `{"path": "${imagePath}"}`
	}

	const image = await fetch(newURL, options)

	if (!image.ok)
		return []
	
	const data = await image.json()

	if (!data)
		return []

	const imgs = data.files

	if (!imgs || !imgs?.length)
		return []

	for (let x = 0; x < imgs.length; x++) {
		const img = imgs[x]
		imgs[x] = url.replace('/?', '/') + img
	}

	return imgs
}

export function downloadApk(){
	window.open(url + 'file', '_blank');
}

