const lang: any = {
    newInspection: {
        PT: 'Solicitar Nova Inspeção',
        US: 'Request New Inspection',
        ES: 'Solicitar Nueva Inspección'
    },

    editInspection: {
        PT: 'Editar Inspeção',
        US: 'Edit Inspection',
        ES: 'Editar Inspección'
    },

    inspector: {
        PT: 'Inspetor',
        US: 'Inspector',
        ES: 'Inspector'
    },

    company: {
        PT: 'Empresa',
        US: 'Company',
        ES: 'Empresa'
    },

    plant: {
        PT: 'Planta',
        US: 'Plant',
        ES: 'Planta'
    },

    process: {
        PT: 'Processo',
        US: 'Process',
        ES: 'Proceso'
    },

    equipment: {
        PT: 'Equipamento',
        US: 'Equipment',
        ES: 'Equipo'
    },

    deadline: {
        PT: 'Prazo',
        US: 'Deadline',
        ES: 'Plazo'
    },

    startDate: {
        PT: 'Data de início',
        US: 'Start date',
        ES: 'Fecha de inicio'
    },

    dateWarning: {
        PT: 'Prazo não pode ser anterior a data de inicio',
        US: 'The deadline cannot be earlier than the start date',
        ES: 'El plazo no puede ser anterior a la fecha de inicio'
    },

    register: {
        PT: 'Cadastrar',
        US: 'Register',
        ES: 'Registrar'
    },

    edit: {
        PT: 'Editar',
        US: 'Edit',
        ES: 'Editar'
    },

    clean: {
        PT: 'Limpar',
        US: 'Clean',
        ES: 'Limpiar'
    },

    addNew: {
        PT: 'Adicionar Novo',
        US: 'Add New',
        ES: 'Agregar Nuevo'
    },

    notification: {
        title: {
            PT: 'Nova inspeção requisitada',
            US: 'New inspection requested',
            ES: 'Nueva inspección solicitada'
        },
        content: {
            PT: ' requisitou a inspeção ',
            US: ' requested the inspection ',
            ES: ' solicitó la inspección '
        }
    },

    type: {
        PT: 'Tipo de Inspeção',
        US: 'Type of Inspection',
        ES: 'Tipo de Inspección'
    },
    
    corrosion: {
        PT: 'Inspeção de Corrosão',
        US: 'Corrosion Inspection',
        ES: 'Inspección de Corrosión'
    },

    painting: {
        PT: 'Inspeção de Pintura',
        US: 'Paint Inspection',
        ES: 'Inspección de Pintura'
    },
    NR13: {
        PT: 'Inspeção de Segurança - Equipamentos (NR13)',
        US: 'Safety Inspection - Equipments (NR13)',
        ES: 'Inspección Seguridad - Equipos (NR13)'
    },
    safetyInspectionAvailability: {
        PT: "O TIPO DE INSPEÇÃO SOLICITADA NÃO ESTÁ DISPONÍVEL PARA ESTE TIPO DE EQUIPAMENTO",
        US: "THE REQUESTED TYPE OF INSPECTION IS NOT AVAILABLE FOR THIS TYPE OF EQUIPMENT",
        ES: "EL TIPO DE INSPECCIÓN SOLICITADA NO ESTÁ DISPONIBLE PARA ESTE TIPO DE EQUIPO"
    },
    
    
    
    
}

export default lang;
