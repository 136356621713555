import { useEffect, useRef, useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import FlagSelector from "../../../../../components/FlagSelector"
import { CustomModalPage } from "../../../../../components/Modal"
import Sidebar from "../../../../../components/Sidebar"
import { faAngleLeft, faFolder } from "@fortawesome/free-solid-svg-icons"
import CustomTextField from "../../../../../components/CustomTextField"
import CustomDropBox from "../../../../../components/CustomDropBox"
import FileUpload from "../../../../../components/FileUpload"
import { FileDocumentUpload } from "../../../../../components/FileUploadDocument"
import { getStoredJson, saveJsonData, useInitialCheck } from "../../../../../utils"
import { getDataBaseCondition, getDatabaseRows } from "../../../../../utils/database"
import { useGlobalContext } from "../../../../../contexts/GlobalContext"
import SwitchButton from "../../../../../components/SwitchButton"
import lang from '../../lang'

type FirstPageProps = {
    navigate: any;
    selectedEquipment: any;
    setSelectedEquipment: (equipment: any) => void;
    setTechnicalData: (techData: any) => void;
    edit: boolean;
    handleManagement: () => void;
    imageData: any;
    setImageData: (img: any) => void;
    fileData: any;
    setFileData: (doc: any) => void;
    modalOpen: boolean;
    setPage: (pageNum: number) => void;
}

export function FirstPage({ 
    navigate, 
    selectedEquipment, 
    setSelectedEquipment, 
    setTechnicalData, 
    edit,
    handleManagement,
    imageData,
    setImageData,
    fileData,
    setFileData,
    modalOpen,
    setPage
}: FirstPageProps) {
    const [fieldChanged, setFieldChanged] = useState(false)
	const [canGoToNextPage, setCanGoToNextPage] = useState(false)
    const [isSwitchOn, setIsSwitchOn] = useState(false);

    const [companies, setCompanies] = useState<any>([])
	const [plants, setPlants] = useState<any>([])
	const [processes, setProcesses] = useState<any>([])
    const [mainEquipments, setMainEquipments] = useState<any>([])
	const [types, setTypes] = useState<any>([]);

    const [equipmentName, setEquipmentName] = useState('')
    const [equipmentType, setEquipmentType] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [plantName, setPlantName] = useState('')
    const [processName, setProcessName] = useState('')
    const [mainEquipmentName, setMainEquipmentName] = useState('')

    const [corrosionWarning, setCorrosionWarning] = useState(false);
    const [nr13Warning, setNR13Warning] = useState(false);

    
    const user = getStoredJson('user')
    const { globalOptions } = useGlobalContext()
    const currLang = globalOptions.language
    const hasShownInitialData = useRef(false)

    const nr13_types = ["Vasos de Pressão","Tanques Atmosféricos","Caldeiras ou Tubulações"]
    const corrosion_types = ["Tubulações, Flanges e Válvulas","Dutos"]

    useInitialCheck(importTypes)
	useInitialCheck(importCompanies)
	useInitialCheck(importPlants, [selectedEquipment.company])
	useInitialCheck(importProcesses, [selectedEquipment.plant])
    useInitialCheck(importMainEquipments, [selectedEquipment.process])

    useEffect(() => {
        if (!hasShownInitialData.current && Object.keys(selectedEquipment).length > 0) {
            hasShownInitialData.current = true

            setEquipmentName(selectedEquipment.name)
            setEquipmentType(isValid(selectedEquipment.equipment_type) ? selectedEquipment.equipment_type.name : '')
            setCompanyName(isValid(selectedEquipment.company) ? selectedEquipment.company.name : '')
            setPlantName(isValid(selectedEquipment.plant) ? selectedEquipment.plant.name : '')
            setProcessName(isValid(selectedEquipment.process) ? selectedEquipment.process.name : '')

            setSelectedEquipment({
                ...selectedEquipment,
                company: selectedEquipment.company,
                plant: selectedEquipment.plant,
                process: selectedEquipment.process
            })

            setFieldChanged(!fieldChanged)
        }
    }, [selectedEquipment])

    useEffect(() => {
        importTypes()
    }, [isSwitchOn])

    useEffect(() => {
        setCanGoToNextPage(
            isValid(equipmentName) &&
            isValid(equipmentType) &&
            isValid(companyName) &&
            isValid(plantName) &&
            isValid(processName)
        )
    }, [fieldChanged])

    async function importCompanies() {
		let data = []

		if (user.company == '123456789/0001')
			data = await getDatabaseRows('companies')
		else
			data = await getDataBaseCondition('companies', 'cnpj', user.company)

		setCompanies(data)
	}

	async function importPlants() {
		const company = selectedEquipment.company
		const response = await getDataBaseCondition('plants', 'company', company ? company.cnpj : '???')

		setPlants(response)
	}

	async function importProcesses() {
		const plant = selectedEquipment.plant
		const response = await getDataBaseCondition('processes', 'plant', plant ? plant.plant_id : '???')
		setProcesses(response)
	}

    async function importMainEquipments() {
		const process = selectedEquipment.process
		const response = await getDataBaseCondition('equipments', 'process', process ? process.process_id : '???')
        let eqp = response.filter((item: any) => item.master == '')
		setMainEquipments(eqp)
	}

	async function importTypes() {
		let response
        
        if (isSwitchOn)
            response = await getDataBaseCondition('equipment_type', 'is_secondary', '1')
        else
            response = await getDataBaseCondition('equipment_type', 'is_secondary', '0')

		setTypes(response)
	}

    function handleNameChange(text: string) {
		setSelectedEquipment({ ...selectedEquipment, name: text })
	}

	function handleCompanyChange(selectedOption: any) {
		if (selectedOption.key && selectedOption.key == 'default')
			return navigate('/Company?insert')

		setSelectedEquipment({...selectedEquipment, company: selectedOption, plant: null, process: null})
	}

	function handlePlantChange(selectedOption: any) {
		if (selectedOption.key && selectedOption.key == 'default') {
			saveJsonData('insert', { company: selectedEquipment.company.cnpj })
			return navigate('/Plant?insert')
		}
		setSelectedEquipment({ ...selectedEquipment, plant: selectedOption, processes: null })
        //alert(JSON.stringify(selectedEquipment.plant))

	}

	function handleProcessChange(selectedOption: any) {
		if (selectedOption.key && selectedOption.key == 'default') {
			saveJsonData('insert', { company: selectedEquipment.company.cnpj, plant: selectedEquipment.plant.plant_id })
			return navigate('/Process?insert')
		}

		setSelectedEquipment({ ...selectedEquipment, process: selectedOption })
	}

    function handleMainEquipmentChange(selectedOption: any) {
		if (selectedOption.key && selectedOption.key == 'default') {
			saveJsonData('insert', { company: selectedEquipment.company.cnpj, plant: selectedEquipment.plant.plant_id, process: selectedEquipment.process.process_id })
			return navigate('/MainEquipment?insert')
		}

		setSelectedEquipment({ ...selectedEquipment, mainEquipment: selectedOption })
	}

    async function setTypeRelatedTechData(equipmentTypeId: string) {
        // Armazena template relacionado ao tipo de dado técnico selecionado
		const response = await getDataBaseCondition('equipment_type', 'id', equipmentTypeId)
	
		if (!response || !response.length || response === undefined)
			return

        const params = JSON.parse(response[0].parameters)
        let techData: any = {};

        for (const lang in params) {
            techData[lang] = {};
            for (const field in params[lang]) {
                techData[lang][field] = { name: params[lang][field]['name'], value: null }
            }
        }
		setTechnicalData(techData)
    }

    useEffect(() => {
        setNR13Warning(false)
        setCorrosionWarning(false)
        handleTypeWarning()
    }, [equipmentType])

    function handleTypeWarning() {
        if (nr13_types.includes(equipmentType))
            return setNR13Warning(true)
        else if (corrosion_types.includes(equipmentType))
            return setCorrosionWarning(true)
    }

	async function handleTypeChange(selectedOption: any) {
		if (selectedOption.key && selectedOption.key == 'default')
			return navigate('/EquipmentType?insert')

        setSelectedEquipment({ ...selectedEquipment, equipment_type: selectedOption })
        await setTypeRelatedTechData(selectedOption.id)
	}

	function handleDropboxValueChange(selectedOption: any, callback: (selOption: any) => void) {
		callback(selectedOption)
		setFieldChanged(!fieldChanged)
	}

	function handleTextInputValueChange(text: string, callback: (text: string) => void) {
		callback(text)
		setFieldChanged(!fieldChanged)
	}

	function isValid(value: any) {
        if (value === null)
            return false
        
        if (typeof value === 'object' && !Object.keys(value).length)
            return false

		return value !== undefined && value !== ''
	}

    const handleToggle = () => {
        setIsSwitchOn(prevState => !prevState);
      };

    return (
        <div className="MainContainer">

            <CustomModalPage
                title={lang.equipmentRegistered[currLang]}
                subtitle={lang.wantToRequestInspection[currLang]}
                yesPage='/Request?insert'
                noPage='/Home'
                yesText={lang.yes[currLang]}
				noText={lang.no[currLang]}
                modal={modalOpen}
            />

            <div className='SideBarCompensation' />

            <div className='CentralBlock' style={{ height: '100vh' }}>
                <FlagSelector />
                <Sidebar activePage='Register' />

                <div className="RegisterMainContainer">

                    <div className="iconContainer" style={{ width: '100%' }}>
                        <div
                            className='iconLeft'
                            style={{ alignSelf: 'center', width: '7%' }}
                            onClick={() => navigate(-1)}
                        >

                            <FontAwesomeIcon icon={faAngleLeft} title="Empresas já cadastrada" />
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '85%' }}>
                            <p className='clientText'>
                                {edit ? lang.editEquipment[currLang] : lang.registerEquipment[currLang]}
                            </p>

                            <div className='folderContainer' onClick={handleManagement}>
                                <FontAwesomeIcon icon={faFolder} title="Equipamentos já cadastrados" />
                            </div>
                        </div>
                    </div>

                    <div style={{ width: '85%' }}>
                        <text className='equipmentText2'>{lang.equipmentInfo[currLang]}</text>
                    </div>

                    <div style={{ padding: '.6rem' }} />

                    <div style={{ width: '85%', display: 'flex', alignItems: 'center', height: '40px' }}>
                        <text className='equipmentText2' style={{ paddingRight: '30px', lineHeight: '40px'}}>{lang.auxiliaryEquipment[currLang]}</text>
                        <SwitchButton isOn={isSwitchOn} onToggle={handleToggle} />
                    </div>

                    <div style={{ padding: '.6rem' }} />

                    <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
                        <CustomTextField
                            placeholder='Tag'
                            value={equipmentName}
                            setValue={(text: string) => { handleTextInputValueChange(text, handleNameChange); setEquipmentName(text) }}
                        />
                    </div>

                    <div style={{ padding: '.6rem' }} />

                    <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
                        <CustomDropBox
                            options={types}
                            value={equipmentType}
                            setValue={(selectedOption: any) => { 
                                handleDropboxValueChange(selectedOption, handleTypeChange)
                                setEquipmentType(selectedOption.name)
                            }}
                            placeholder={lang.equipmentType[currLang]}
                            key='name'
                        />

                        {nr13Warning ? <p style={{ color: '#F36934'}}>{lang.nr13Warning[currLang]}</p> :  null}
                    </div>

                    <div style={{ padding: '.6rem' }} />

                    <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
                        <CustomDropBox
                            options={companies}
                            value={companyName}
                            setValue={(selectedOption: any) => {
                                setCompanyName(selectedOption.name)
                                handleDropboxValueChange(selectedOption, handleCompanyChange)
                                if (!isValid(selectedOption) || (isValid(selectedEquipment.company) && selectedOption.id !== selectedEquipment.company.id)) {
                                    setPlantName('')
                                    setProcessName('')
                                }
                            }}
                            placeholder={lang.company[currLang]}
                            key='name'
                            callbackKey={lang.addNew[currLang]}
                        />
                    </div>

                    <div style={{ padding: '.6rem' }} />

                    <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
                        <CustomDropBox
                            options={plants}
                            value={plantName}
                            setValue={(selectedOption: any) => { 
                                setPlantName(selectedOption.name)
                                handleDropboxValueChange(selectedOption, handlePlantChange)
                                if (!isValid(selectedOption) || (isValid(selectedEquipment.plant) && selectedOption.plant_id !== selectedEquipment.plant.plant_id))
                                    setProcessName('')
                            }}
                            placeholder={lang.plant[currLang]}
                            key='name'
                            callbackKey={lang.addNew[currLang]}
                        />
                    </div>

                    <div style={{ padding: '.6rem' }} />

                    <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
                        <CustomDropBox
                            options={processes}
                            value={processName}
                            setValue={(selectedOption: any) => { 
                                handleDropboxValueChange(selectedOption, handleProcessChange)
                                setProcessName(selectedOption.name)
                            }}
                            placeholder={lang.process[currLang]}
                            key='name'
                            callbackKey={lang.addNew[currLang]}
                        />
                    </div>

                    {isSwitchOn && (
                        <>
                            <div style={{ padding: '.6rem' }} />

                            <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
                                <CustomDropBox
                                    options={mainEquipments}
                                    value={mainEquipmentName}
                                    setValue={(selectedOption: any) => { 
                                        handleDropboxValueChange(selectedOption, handleMainEquipmentChange)
                                        setMainEquipmentName(selectedOption.name)
                                    }}
                                    placeholder={lang.mainEquipment[currLang]}
                                    key='name'
                                />
                            </div>
                        </>
                    )}

                    <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
                        <FileDocumentUpload
                            fileData={fileData}
                            setFileData={setFileData}
                            title={lang.documents[currLang]}
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>
                        <FileUpload
                            title={lang.photos[currLang]}
                            setImg={setImageData}
                            img={imageData.length > 0 ? imageData[0] : null}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%' }}>

                        <button
                            className='button-equipment-new'
                            onClick={() => setPage(2)}
                            style={!canGoToNextPage ? { opacity: 0.8, cursor: 'not-allowed' } : {}}
                            disabled={!canGoToNextPage}
                        >
                            {lang.next[currLang]}
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}