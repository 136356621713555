import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';
import lang from './lang';
import { useGlobalContext } from '../../contexts/GlobalContext';

const sxOptions = {
	width: '100%',
	backgroundColor: '#fff',
	borderRadius: '1rem',
	'.MuiInputLabel-outlined.MuiInputLabel-shrink': {
		transform: 'translate(14px, 1px) scale(0.8)',
		fontSize: '12px',
	},
	'.MuiAutocomplete-listbox': {
		fontSize: '12px',
		maxHeight: 'none', // Remove o limite de altura
	},
};

const sxTextFieldOptions = {
	'.MuiOutlinedInput-notchedOutline': {
		border: 'none',
		borderRadius: '1rem',
	},
	'&:hover .MuiOutlinedInput-notchedOutline': {
		border: 'none',
	},
	'.Mui-focused .MuiOutlinedInput-notchedOutline': {
		border: 'none',
	},
	'.MuiInputBase-input': {
		fontSize: '12px',
		height: 'auto', // Ajusta a altura dinamicamente
	},
	'.MuiInputLabel-outlined': {
		fontSize: '13px',
		opacity: 1,
	},
};


export default function CustomDropBox({ options, value, setValue, placeholder, key = 'label', callbackKey = null, multiSelection = false, disabled = false }: any) {
	const [currValue, setCurrValue] = useState<any>(multiSelection ? [] : null);
	const { globalOptions } = useGlobalContext();
	const currLang = globalOptions.language;

	options = options.map((item: any) => ({ ...item, label: item.name }));

	if (callbackKey) options = [{ label: callbackKey, key: 'default' }, ...options];

	useEffect(() => {
		if (multiSelection && !Array.isArray(value)) {
			setCurrValue([]);
		} else {
			setCurrValue(value);
		}
	}, [value, multiSelection]);

	const handleChange = (event: any, newValue: any) => {
		if (multiSelection) {
			setCurrValue(newValue);
			setValue(newValue ? newValue : []);
		} else {
			setCurrValue(newValue);
			setValue(newValue ? newValue : {});
		}
	};

	return (
		<Autocomplete
			multiple={multiSelection}
			disablePortal
			id="combo-box-demo"
			options={options}
			sx={sxOptions}
			value={currValue}
			onChange={handleChange}
			noOptionsText={lang.nodata[currLang]}
			disabled={disabled} 
			renderInput={(params) => (
				<TextField
					{...params}
					label={placeholder}
					sx={sxTextFieldOptions}
					disabled={disabled} 
				/>
			)}
		/>
	);
}



/*export default function CustomDropBox({ options, value, setValue, placeholder, key = 'label', callbackKey = null }: any) {
	const [currValue, setCurrValue] = useState<string|null>(null)
	const {globalOptions, setGlobalOptions} = useGlobalContext()

	const currLang = globalOptions.language

	options = options.map((item: any) => ({ ...item, label: item.name }))

	if (callbackKey)
		options = [{ label: callbackKey, key: 'default' }, ...options]

	useEffect(() => { setCurrValue(value) }, [value])

	return (
		<Autocomplete
			disablePortal
			id="combo-box-demo"
			options={options}
			sx={sxOptions}
			value={currValue}
			onChange={(event, newValue) => { setCurrValue(newValue); setValue(newValue ? newValue : {}) }}
			noOptionsText={lang.nodata[currLang]}
			renderInput={(params) => (
				<TextField
					{...params}
					label={placeholder}
					sx={sxTextFieldOptions}
				/>
			)}
		/>
	);
}*/