const lang: any = {
    registerInspector: {
        PT: 'Cadastrar Novo Inspetor',
        US: 'Register New Inspector',
        ES: 'Registrar Nuevo Inspector'
    },

    editInspector: {
        PT: 'Editar inspetor',
        US: 'Edit Inspector',
        ES: 'Editar Inspector'
    },

    inspectorInfo: {
        PT: 'INFORMAÇÕES DO USUÁRIO',
        US: 'USER INFO',
        ES: 'INFORMACIÓN DEL USUARIO'
    },

    name: {
        PT: 'Nome',
        US: 'Name',
        ES: 'Nombre'
    },

    password: {
        PT: 'Senha',
        US: 'Password',
        ES: 'Contraseña'
    },

    level: {
        PT: 'Nível',
        US: 'Level',
        ES: 'Nivel'
    },

    cpf: {
        PT: 'CPF',
        US: 'CPF',
        ES: 'CPF'
    },

    register: {
        PT: 'Cadastrar',
        US: 'Register',
        ES: 'Registrar'
    },

    edit: {
        PT: 'Editar',
        US: 'Edit',
        ES: 'Editar'
    },

    inspectorRegistered: {
        PT: "Inspetor Cadastrado",
        US: "Inspector Registered",
        ES: "Inspector Registrado"
    },
    
    wantToRegisterClient: {
        PT: "Deseja Cadastrar um Cliente?",
        US: "Do You Want to Register a Client?",
        ES: "¿Desea Registrar un Cliente?"
    },
    invalidEmail: {
        PT: "Email Inválido!",
        US: "Invalid Email!",
        ES: "¡Correo Electrónico Inválido!"
    },
    invalidCPF: {
        PT: "CPF Inválido!",
        US: "Invalid CPF!",
        ES: "¡CPF Inválido!"
    },
    yes: {
        PT: "Sim",
        US: "Yes",
        ES: "Sí"
    },
    
    no: {
        PT: "Não",
        US: "No",
        ES: "No"
    },
    services: {
        PT: "Serviços",
        US: "Services",
        ES: "Servicios"
    },
    corrosion: {
        PT: "Inspeção de Corrosão",
        US: "Corrosion Inspection",
        ES: "Inspección de Corrosión"
    },
    
    painting: {
        PT: "Inspeção de Pintura",
        US: "Painting Inspection",
        ES: "Inspección de Pintura"
    },
    
    nr13: {
        PT: "Inspeção de Segurança - Equipamentos (NR13)",
        US: "Safety Inspection - Equipment (NR13)",
        ES: "Inspección de Seguridad - Equipos (NR13)"
    },
    
    
}

export default lang;
