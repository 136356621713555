const lang: any = {
    export: {
        PT: 'Exportar',
        US: 'Export',
        ES: 'Exportar'
    },

    visualInspection: {
        PT: 'Inspeção Visual',
        US: 'Visual Inspection',
        ES: 'Inspección Visual'
    },

    measurements: {
        PT: 'Medições',
        US: 'Measurements',
        ES: 'Mediciones'
    },

    photos: {
        PT: 'Fotos',
        US: 'Photos',
        ES: 'Fotos'
    },

    createdOn: {
        PT: 'Criado em',
        US: 'Created On',
        ES: 'Creado En'
    },

    company: {
        PT: 'Empresa',
        US: 'Company',
        ES: 'Empresa'
    },

    plant: {
        PT: 'Planta',
        US: 'Plant',
        ES: 'Planta'
    },

    process: {
        PT: 'Processo',
        US: 'Process',
        ES: 'Proceso'
    },

    inspectorName: {
        PT: 'Nome do Inspetor',
        US: 'Inspector Name',
        ES: 'Nombre del Inspector'
    },

    equipment: {
        PT: 'Equipamento',
        US: 'Equipment',
        ES: 'Equipo'
    },

    lastInspection: {
        PT: 'Última Inspeção',
        US: 'Last Inspection',
        ES: 'Última Inspección'
    },

    itemDescription: {
        PT: 'Descrição do Item',
        US: 'Item Description',
        ES: 'Descripción del Ítem'
    },

    equipmentName: {
        PT: 'Nome do Equipamento',
        US: 'Equipment Name',
        ES: 'Nombre del Equipo'
    },

    substrate: {
        PT: 'Substrato',
        US: 'Substrate',
        ES: 'Sustrato'
    },

    temperature: {
        PT: 'Temperatura',
        US: 'Temperature',
        ES: 'Temperatura'
    },

    heightWork: {
        PT: 'Altura de Trabalho',
        US: 'Work Height',
        ES: 'Altura de Trabajo'
    },

    insulation: {
        PT: 'Insulação',
        US: 'Insulation',
        ES: 'Aislamiento'
    },

    evaluatedPoints: {
        PT: 'Pontos Avaliados',
        US: 'Evaluated Points',
        ES: 'Puntos Evaluados'
    },

    typeOfRepair: {
        PT: 'Tipo de reparo',
        US: 'Type of Repair',
        ES: 'Tipo de Reparación'
    },

    access: {
        PT: 'Acesso',
        US: 'Access',
        ES: 'Acceso'
    },

    repairHeight: {
        PT: 'Altura do Reparo',
        US: 'Repair Height',
        ES: 'Altura de la Reparación'
    },

    recommendations: {
        PT: 'Recomendação',
        US: 'Recommendations',
        ES: 'Recomendaciones'
    },

    close: {
        PT: 'Fechar',
        US: 'Close',
        ES: 'Cerrar'
    },

    exportXLSX: {
        PT: 'Exportar como XLSX',
        US: 'Export as XLSX',
        ES: 'Exportar como XLSX'
    },

    generalInformation: {
        PT: 'Informações Gerais',
        US: 'General Information',
        ES: 'Información General'
    },

    edit: {
        PT: 'Editar',
        US: 'Edit',
        ES: 'Editar'
    },
    area: {
        PT: "Área",
        US: "Area",
        ES: "Área"
    },
    
    problem: {
        PT: "Problema",
        US: "Problem",
        ES: "Problema"
    },
    
    location: {
        PT: "Localização",
        US: "Location",
        ES: "Ubicación"
    },
    
    description: {
        PT: "Descrição",
        US: "Description",
        ES: "Descripción"
    },
    
}

export default lang;
