
import { faAngleLeft, faChevronRight, faFileLines, faFilter, faFileImage, faSortAlphaAsc } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import './styles.css'
import lang from './lang'

import { getStoredJson, saveJsonData, sleep, useInitialCheck } from "../../../utils";
import {getDataBaseCondition, getDatabaseRows } from '../../../utils/database';
import FlagSelector from "../../../components/FlagSelector";
import Sidebar from "../../../components/Sidebar";
import  FilterPopUp from '../../../components/Filter'
import { Badge } from "@mui/material";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import LoadingDiv from "../../../components/LoadingDiv";
import ScrollView from "../../../components/ScrollView";


export function ReportsPage(){
    const [searchText, setSearchText] = useState<any>('');
    const [modalOpen, setModalOpen] = useState(false)
    const [totalSum, setTotalSum] = useState(0);
    const [desiredData, setDesiredData] = useState<any>([])
    const [navFilter, setNavfilter] = useState(false)
    const [navRegister, setNavRegister] = useState(false)
    const [navReport, setNavReport] = useState(false)
    const [selectedData, setSelectedData] = useState<any>({})
    const [update, setUpdate] = useState<boolean>(true)
    const user = getStoredJson('user')

    const {globalOptions, setGlobalOptions} = useGlobalContext()
    const currLang = globalOptions.language
    const navigate = useNavigate();
    
    async function importInfo() {
        await sleep(500)
        let dbRInspections = await getDatabaseRows('inspections');
        let dbInspections = dbRInspections.filter((item: any) => item.approved == 1)

        if (user.company !== '123456789/0001')
            dbInspections = dbInspections.filter((item: any) => item.company === user.company)

        const filterItems = getStoredJson('filterItems');
        setTotalSum(0)
        //alert(JSON.stringify(selectedData))

        let filteredByCompany: any = [];
        let filteredByPlant = [];
        let filteredByProcess = [];
        let filteredByEquipment = [];
        let filteredByInspector = [];
        let finalFilteredResults = [];
        let support: any = [];

        if(navRegister){
            let dbRegisters = await getDatabaseRows('registers')
            filteredByCompany = dbRegisters.filter((item: any) => item.company == selectedData?.company);

            if(filteredByCompany.length > 0){
                finalFilteredResults = filteredByCompany
            } else {
                support = dbRInspections.filter((item: any) => item.plant == selectedData?.plant)
                support = support.map((it: any) => it.id)
                filteredByPlant = dbRegisters.filter((item: any) => support.includes(item.inspection_id));

                if(filteredByPlant.length > 0)
                    finalFilteredResults = filteredByPlant
                else {
                    support = dbRInspections.filter((item: any) => item.process == selectedData?.process)
                    support = support.map((it: any) => it.id)
                    filteredByProcess = dbRegisters.filter((item: any) => support.includes(item.inspection_id));

                    if(filteredByProcess.length > 0)
                        finalFilteredResults = filteredByProcess
                    else {
                        support = dbRInspections.filter((item: any) => item.equipment == selectedData?.equipment)
                        support = support.map((it: any) => it.id)
                        filteredByEquipment = dbRegisters.filter((item: any) => support.includes(item.inspection_id));

                        if(filteredByEquipment.length > 0)
                            finalFilteredResults = filteredByEquipment
                        else {
                            support = dbRInspections.filter((item: any) => item.inspector == selectedData.inspector?.name)
                            support = support.map((it: any) => it.id)
                            filteredByInspector = dbRegisters.filter((item: any) => support.includes(item.inspection_id));
                            if(filteredByInspector.length > 0)
                                finalFilteredResults = filteredByInspector
                        }
                    }
                }     
            }
        } else if(navFilter){
            filteredByCompany = dbInspections.filter((item: any) => item.company_id == selectedData?.company);
            if(filteredByCompany.length > 0)
                finalFilteredResults = filteredByCompany
            else {
                filteredByPlant = dbInspections.filter((item: any) => item.plant == selectedData?.plant);
                if(filteredByPlant.length > 0)
                    finalFilteredResults = filteredByPlant
                else {
                    filteredByProcess = dbInspections.filter((item: any) => item.process == selectedData?.process);
                    if(filteredByProcess.length > 0)
                        finalFilteredResults = filteredByProcess
                    else {
                        filteredByEquipment = dbInspections.filter((item: any) => item.equipment == selectedData?.equipment);
                        if(filteredByEquipment.length > 0)
                            finalFilteredResults = filteredByEquipment
                        else {
                            filteredByInspector = dbInspections.filter((item: any) => item.inspector == selectedData.inspector?.name);
                            if(filteredByInspector.length > 0)
                                finalFilteredResults = filteredByInspector
                        }
                    }
                }
            }
        } else if (navReport) {
            if (filterItems != null){
                for (const key in filterItems) {
                    setTotalSum((prevTotalSum) => prevTotalSum + filterItems[key].length);
                }
                // Filtro por empresa
                if (filterItems.company && filterItems.company.length > 0) {
                        filteredByCompany = dbInspections.filter((item: { company: any; }) =>
                            filterItems.company.some((company: { cnpj: any; }) => item.company === company.cnpj)
                        );
                } else {
                    filteredByCompany = dbInspections;
                }
        
                // Filtro por planta
                if (filterItems.plant && filterItems.plant.length > 0) {
                    filteredByPlant = filteredByCompany.filter((item: { plant: any; }) =>
                        filterItems.plant.some((plant: { plant_id: any; }) => item.plant === plant.plant_id)
                    );
                } else {
                    filteredByPlant = filteredByCompany;
                }
        
                // Filtro por processo
                if (filterItems.process && filterItems.process.length > 0) {
                    filteredByProcess = filteredByPlant.filter((item: { process: any; }) =>
                        filterItems.process.some((process: { process_id: any; }) => item.process === process.process_id)
                    );
                } else {
                    filteredByProcess = filteredByPlant;
                }

            // Filtro por equipamento

            if (filterItems.equipment && filterItems.equipment.length > 0) {
                    filteredByEquipment = filteredByProcess.filter((item: { equipment: any; }) =>
                        filterItems.equipment.some((equipment: { equipment_id: any; }) => item.equipment == equipment.equipment_id)
                    );
                } else {
                    filteredByEquipment = filteredByProcess;
                }

                // Filtro por inspetor
                if (filterItems.inspector && filterItems.inspector.length > 0) {
                    finalFilteredResults = filteredByEquipment.filter((item: { inspector: any; }) =>
                        filterItems.inspector.some((inspector: { name: any; }) => inspector.name === item.inspector)
                    );
                } else {
                    finalFilteredResults = filteredByEquipment;
                }
        }
        }
        // Atualize o estado desejado com os resultados filtrados
        //alert(JSON.stringify(finalFilteredResults))
        setDesiredData(finalFilteredResults);
        setUpdate(false)
    };

    async function checkFilter() {
        const currentUrl = window.location.href;
        const isFilter = currentUrl.indexOf('?filter') !== -1;
        setNavfilter(isFilter);

        if (!isFilter) {
            return;
        }

        const filterData = getStoredJson('filter');
        setSelectedData(filterData);
        await importInfo();
    }

    async function checkRegister() {
        const currentUrl = window.location.href;
        const isRegister = currentUrl.indexOf('?register') !== -1;

        setNavRegister(isRegister);

        if (!isRegister) {
            return;
        }

        const registerData = getStoredJson('register');
        setSelectedData(registerData);
        await importInfo();
    }

    async function checkReport() {
        const currentUrl = window.location.href;
        const isBase = currentUrl.indexOf('?base') !== -1;

        setNavReport(isBase);

        if (!isBase) {
            return;
        }

        await importInfo();
    }

    useEffect(() => {
        checkFilter();
        checkRegister();
        checkReport();
    }, [navFilter, navRegister, navReport]);

    useInitialCheck(handleChangeText, [searchText])

  
    function handleChangeText(){
        if(searchText.trim().length == 0)
            return importInfo()
        
        setDesiredData(desiredData.filter((item: any) => compare(item.title, searchText)))
    }

    function compare(original: string, text: string){

        if (typeof original !== 'string' || typeof text !== 'string') {
            return false;
        }
        original = original.trim().toLowerCase()
        text     = text.trim().toLowerCase()

        return original.includes(text)
    }

    const clearFilters = () => {
        saveJsonData('filterItems', {});
    };
    const location = useLocation();

    useEffect(() => {
        clearFilters(); 
    }, [location.pathname]); 
    useEffect(() => {
        importInfo();
    }, [modalOpen]);
    

    function filter()
    {
        setModalOpen(true)
    }

    function handleNextPage(item: any){
        saveJsonData('pendingInspection', item)
        
        if(navRegister)
            return navigate('/Register')
        else
            return navigate('/Explore')
      }
    

    function renderLastInspection(item: any){
        const relatory = item.relatory ? JSON.parse(item.relatory) : {}
        let isEdit   = Object.keys(relatory).length == 0
        //let support = supportData.filter((it: any) => it.id == item.inspection_id)
        //support = support.map((it: any) => it.title)
        if (navRegister)
            isEdit = false

        return !isEdit && (
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div className="item_Report">
            <FontAwesomeIcon icon={navRegister ? faFileImage : faFileLines} className="iconLeft" style={{fontSize: '1.5rem', paddingRight: '1rem'}}/>
              <span className="date_Report">{item.title}</span>

              <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center'}}>  
                <button className="arrow_Home">
                    <FontAwesomeIcon icon={faChevronRight} style={{fontSize: '1rem'}} onClick={()=>handleNextPage(item)}/>
                </button>
              </div>
            </div>

            <div className="line_reports" />
          </div>
        )
    }

    return (
    <div className="MainContainer">
        <FilterPopUp modal={modalOpen} setModalOpen={setModalOpen} setUpdate={setUpdate} />
        <FlagSelector/>
        <Sidebar />
        
        <div className='SideBarCompensation'/>
        
        <div className="CentralBlock">
            <ScrollView className="ReportInfoContainer">
                <div style={{flexDirection: 'row', display: 'flex'}}>
                    <FontAwesomeIcon icon={faAngleLeft} className="iconLeft" style={{fontSize: '1.8rem', paddingRight: '1.2rem',}} onClick={() => navigate('/Home')} />

                    <div className='reportInputContainer'>
                        <input
                            type="text"
                            value={searchText}
                            onChange={(event: any) => setSearchText(event.target.value)}
                            placeholder={lang.typeHere[currLang]}
                            className='reportInput'
                        />
                    </div>
                        
                    <FontAwesomeIcon icon={faFilter} className="iconLeft" style={{fontSize: '1.5rem', paddingLeft: '1.2rem'}} onClick={filter}/>
                    
                    <div style={{width: '0.8%'}}>
                        <Badge badgeContent={totalSum} color="warning" max={9} showZero >
                        </Badge>
                    </div>

                    <FontAwesomeIcon icon={faSortAlphaAsc} className="iconLeft" style={{fontSize: '1.5rem', paddingLeft: '1.2rem', paddingRight: '1.2rem',}}/>
                </div>

                <div style={{padding: '1.5rem'}}/>

                <LoadingDiv loading={update} className="inspectorList_Home">
                    {desiredData.map((item: any) => renderLastInspection(item))}
                </LoadingDiv>
            </ScrollView>
        </div>
    </div>
    )
}